const DELIVERY = [
  {
    number: "01",
    title: "Business case assessment ",
    text:
      "We build technology around our client’s business processes rather than disrupt processes " +
      "for the sake of technology. That’s why we perform rigorous business analysis prior to other work.",
  },
  {
    number: "02",
    title: "Building a proof of concept",
    text:
      "We create a viable PoC that incorporates key features that our client wants to see in the final solution." +
      " From here, we can plan the solution delivery schedule in great detail and with full transparency for both sides.",
  },
  {
    number: "03",
    title: "Fulfilling the project milestones ",
    text:
      "We utilize agile methodologies to deliver incremental value from day one, ensure frequent releases, " +
      "and continuously collect our client’s feedback for perfect results.",
  },
  {
    number: "04",
    title: "Rolling out the final solution",
    text:
      "Before deployment to production, we perform end-to-end testing on the client’s staging environment" +
      " to ensure all the performance, usability, and security criteria are met. Then, finally, we go live.",
  },
  {
    number: "05",
    title: "Post-production support",
    text:
        "Whenever our clients have the need for post-production system support, we are gladly willing to extend our business relationship and ensure our client’s solutions are up and running at all times.",
  },
];

export default DELIVERY;
