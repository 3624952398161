export const DEVOPS_MENU_TITLE = 'DevOps';
export const DEVOPS_HEADING = ['DevOps as a Service'];
export const DEVOPS_SUBHEADING = [
  'With our experienced team of world-class DevOps engineers, CyberVision offers a broad spectrum of DevOps consulting services, DevOps staff augmentation, and cutting-edge CloudOps and DevSecOps services.'
];
export const DEVOPS_CONSULTING_SERVICES_HEADING = 'DevOps Consulting Services';
export const DEVOPS_CONSULTING_SERVICES_TEXT = 'CyberVision offers DevOps services to companies wanting to take advantage of modern approaches to software development and streamline their delivery processes. Our experts can take care of all the infrastructure, integration, and deployment routines needed for your product development so that you can focus on better quality and faster go-to market strategies.';
export const DEVOPS_CONSULTING_SERVICES_CARDS = [
  {
    icon: 're-ci-cd',
    title: 'Release engineering, CI/CD',
    text: 'Build and automate CI/CD pipelines to ensure frequent releases of your software and minimize code integration challenges. Use modern build systems and deployment tools to enable your software engineers to efficiently run their release processes, with minimal time and effort.'
  },
  {
    icon: 'sdlc',
    title: 'SDLC automation',
    text: 'Release applications faster by automating major parts of the software development lifecycle (SDLC), from requirements management and source code integration to testing and deployment. Create an efficient collaborative environment by implementing industry-leading automation tools.'
  },
  {
    icon: 'infrastructure-monitoring-dr',
    title: 'Infrastructure monitoring, disaster recovery (DR)',
    text: 'Ensure real-time tracking and analysis of your application infrastructure, including hardware, software, and cloud services that it may be using. Collect performance metrics to ensure efficient allocation of resources, early detection of bugs and issues, and minimized risk of downtime. Set up a disaster recovery pipeline to protect your system from potential disruptions.'
  },
  {
    icon: 'sre',
    title: 'Site reliability engineering (SRE)',
    text: 'Ensure high reliability of your solutions by streamlining typical operations routines and support processes, including incident management, IT support, support escalation, and knowledge management. Implement software tools that improve visibility of potential issues at every stage of the software delivery process.'
  },
  {
    icon: 'devops-team-augmentation',
    title: 'DevOps team augmentation',
    text: 'Improve the quality of your DevOps by augmenting your team with experienced DevOps engineers on full-time or part-time basis. Address specific weaknesses in your SDLC by having DevOps professionals with hands-on experience in the required areas and for a required period of time.'
  },
  {
    icon: 'capacity-performance-management',
    title: 'Capacity and performance management',
    text: 'Efficiently manage your cloud infrastructure resources to ensure cost-efficiency and meet SLA commitments, track performance metrics of your cloud infrastructure and services, ensure quick and reliable deployment of your solution on any type of cloud infrastructure, set up automated capacity and performance dashboards.'
  },
  {
    icon: 'security-integration',
    title: 'Security integration',
    text: 'Implement the best security practices for your applications, users, and data stores. Introduce modern tools for managing user identity and access rights, secure device data, integrate and protect data across multiple data stores, and enforce security policies inside your organization.'
  },
  {
    icon: 'migration-cloudops',
    title: 'Migration between bare metal and cloud, CloudOps',
    text: 'Transfer your data and applications to the cloud or back to your own data center. Deploy your solution on a multi-cloud or hybrid infrastructure, optimize its cloud-native technology stack, and automate cluster management routines for fast and easy deployment of your cloud services.'
  }
];
export const DEVOPS_DEDICATED_TEAM_HEADING = 'Dedicated DevOps Team';
export const DEVOPS_DEDICATED_TEAM_SUBHEADING = 'We can assemble a DevOps team for your project according to your requirements and in a short period of time. With our on-staff DevOps engineers and strong recruitment culture, we can help you minimize the run-up time for your new initiatives, address ad-hoc DevOps needs, and keep up with the digital transformation pace in your industry.';
export const DEVOPS_DEDICATED_TEAM_CARDS = [
  {
    title: 'Fully dedicated DevOps team',
    icon: 'team'
  },
  {
    title: 'World-class engineers',
    icon: 'engineers'
  },
  {
    title: 'Cherry-picked talent and expertise',
    icon: 'talent-expertise'
  },
  {
    title: 'Easily scalable',
    icon: 'scalable'
  }
];
export const DEVOPS_SUCCESS_STORIES_SUBHEADING = [
  'CyberVision has been a go-to DevOps partner for many global businesses, which we helped with full-scale DevOps managed services as well as project-based DevOps team augmentation. We can find the best DevOps solution for your business.'
];
export const DEVOPS_WHY_HEADING = 'Why DevOps?';
export const DEVOPS_WHY_DESCRIPTION =
  'DevOps is an essential part of modern software development that enables delivery teams to embrace a systematic approach to operations and process automation, thus allowing for faster development cycles, increased efficiency, and reduced cost and risk.';
export const DEVOPS_WHY_CARDS = [
  {
    icon: 'products',
    title: 'Create better products',
    text:
      'Leverage modern CI / CD practices to set up smooth release management, code quality control, and transparent deployment procedures',
  },
  {
    icon: 'time',
    title: 'Save time',
    text:
      'Build, test and release your applications quicker and with less effort by utilizing DevOps automation tools and establishing streamlined deployment pipelines',
  },
  {
    icon: 'reliability',
    title: 'Ensure reliability',
    text:
      'Use knowledge and experience of our DevOps engineers to run your solution delivery process in a reliable, secure, and transparent manner from start to finish',
  },
  {
    icon: 'operations',
    title: 'Streamline operations',
    text:
      'Avoid process bottlenecks, eliminate unnecessary manual labour, and improve your team’s productivity by automating your operations with DevOps',
  },
  {
    icon: 'tools',
    title: 'Learn best tools and practices',
    text:
      'Get up to speed with modern DevOps / CloudOps solutions and learn how to effectively apply them for your business',
  },
  {
    icon: 'scalable',
    title: 'Scale your business',
    text:
      'Use DevOps managed services to quickly scale up your business or kick start a new development project, or request DevOps consulting to train your new team',
  },
];

export const DEVOPS_TECHNOLOGIES_BLOCK_HEADING = 'DevOps Technologies';
export const DEVOPS_TECHNOLOGIES_BLOCK_SUBHEADING = 'We use popular DevOps technologies, tools, and frameworks depending on what matches our client’s project needs. We can also help you select the best tools and infrastructure from scratch, customize existing DevOps solutions, or develop some custom tools.';
export const DEVOPS_TECHNOLOGIES_CARDS = [
  [
    {
      mobileIndex: 0,
      title: 'Infrastructure',
      cardLogos: ['kubernets', 'helm', 'terraform', 'docker', 'ansible', 'chef', 'puppet']
    },
    {
      mobileIndex: 3,
      title: 'Cloud',
      cardLogos: ['aws', 'google-cloud', 'azure', 'openstack']
    }
  ],
  [
    {
      mobileIndex: 1,
      title: 'CI/CD',
      cardLogos: ['gitlab', 'jenkins', 'github', 'bitbucket']
    },
    {
      mobileIndex: 2,
      title: 'Monitoring',
      cardLogos: ['grafana', 'prometheus', 'elk', 'new-relic', 'splunk']
    }
  ]
];
