import React from 'react';
import styles from './TopPageBanner.module.scss'
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowForward } from '@material-ui/icons';
import logo from "./live.svg";

const TopPageBanner = ({ onClose }) => {
  const handleCloseClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (onClose) {
      onClose();
    }
  }

  const handleBannerClick = () => {
    window.open('https://bit.ly/WebinarEnvironmentalMonitoring', '_blank');
  }

  return <div className={styles['top-page-heading']} onClick={handleBannerClick}>
    <div className={styles['top-page-heading-body']}>
      <div className={styles['top-page-heading-logo']}>
        <img src={logo} alt="" />
      </div>
      <div className={styles['top-page-heading-flex']}>
        <div className={styles['top-page-heading-message']}>
          <span className={styles['inner-span']}>Webinar</span>
          <span>IoT Environmental Monitoring: Transforming Data into Decisions</span>
        </div>
        <div className={styles['top-page-heading-date']}>
          <span className={styles['top-page-heading-date-text']}>NOV 14</span>
          <span className={styles['top-page-heading-time-text']}>12 pm EST | 5 pm BST | 8 pm GST</span>
        </div>
      </div>
      <div className={styles['top-page-heading-cta']}>
        <Button color={'primary'} variant={'contained'}>
          Register now &nbsp;
          <ArrowForward />
        </Button>
      </div>
    </div>
    <div className={styles['top-page-heading-cta-small']}>
      <Button color={'primary'} variant={'contained'}>
        <ArrowForward />
      </Button>
    </div>
    <CloseIcon className={styles['top-page-heading-close']} onClick={handleCloseClick} />
  </div>
}

export default TopPageBanner;