import React, {useState, useEffect} from 'react';
import styles from './Header.module.scss';
import {HEADER_MENU} from '../../constants';
import Logo from '../../assets/images/logo.svg';
import Button from '@material-ui/core/Button';
import Mail from '@material-ui/icons/MailOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import GatsbyLink, {navigate} from 'gatsby-link';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const HEADER_SHADOW = '0 4px 36px rgba(24, 31, 73, 0.08)';

const Header = ({mainPage, location}) => {
  const [textColor, setTextColor] = useState('secondary');
  const [shadow, setShadow] = useState(mainPage ? 'none' : HEADER_SHADOW);
  const [buttonColor, setButtonColor] = useState('primary');
  const [logoColor, setLogoColor] = useState('#fff');
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [styleMenu, setStyleMenu] = useState(
    mainPage ? 'header__menu-group-main' : ''
  );
  const [current, setCurrent] = useState({
    activeItem: null,
    open: false,
    isMobileOpen: false
  });
  const [currentSubItem, setCurrentSubItem] = useState({
    activeItem: null,
    open: false,
    isMobileOpen: false
  });

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const headerColorChanger = () => {
      if (mainPage) {
        if (window.scrollY > 0) {
          setStyleMenu('');
          setTextColor('primary');
          setButtonColor('secondary');
          setLogoColor('#1A3C94');
          setBackgroundColor('#fff');
          setShadow(HEADER_SHADOW);
        } else {
          setStyleMenu('header__menu-group-main');
          setTextColor('secondary');
          setButtonColor('primary');
          setLogoColor('#fff');
          setBackgroundColor('transparent');
          setShadow('none');
        }
      }
    };
    window.addEventListener('scroll', headerColorChanger);

    return () => window.removeEventListener('scroll', headerColorChanger);
  }, [mainPage]);

  const useStyles = makeStyles({
    logo: {
      '& path': {
        fill: mainPage ? (isMobileMenuOpen ? '1A3C94' : logoColor) : '1A3C94',
      },
      marginBottom: -7,
    },
    header: {
      ...(mainPage ? { backgroundColor } : { backgroundColor: '#fff' }),
      transition: 'background-color 0.2s ease',
    },
  });
  const classes = useStyles();
  const logoClasses = classNames({
    [styles['header']]: true,
    [classes.header]: true,
  });

  const handleOpen = (item) => {
    item.isOpen = true;
    setCurrent({
      activeItem: item,
      open: true
    })
  };

  const handleClose = (event, item) => {
    if (item.anchorRef.current && item.anchorRef.current.contains(event.currentTarget)) {
      return;
    }
    item.isOpen = false;
    setCurrent({
      open: false
    });
    setCurrentSubItem({
      open: false
    });
  };

  const handleSubItemOpen = (item, index) => {
    if (currentSubItem.activeItem) {
      currentSubItem.activeItem.isOpen = false;
    }
    item.isOpen = true;
    setCurrentSubItem({
      activeItem: item,
      index,
      open: true
    });
  };

  const handleSubItemClose = (event, item) => {
    if (item.anchorRef && item.anchorRef.current && item.anchorRef.current.contains(event.currentTarget)) {
      return;
    }
    item.isOpen = false;
    setCurrentSubItem({
      open: false
    });
  };

  const isActive = value => location && location.pathname === value;

  return (
    <div
      style={{
        boxShadow: shadow,
        background: mainPage
          ? isMobileMenuOpen
            ? 'white'
            : backgroundColor
          : '#fff',
      }}
      className={logoClasses}
    >
      <GatsbyLink to="/">
        <Logo className={classes.logo}/>
      </GatsbyLink>
      <div
        className={`${styles[`header__menu-group`]} ${styles[`${styleMenu}`]}`}
      >
        {HEADER_MENU.map((item, index) => {
          if (item.children && item.children.length) {
            return (
              <div
                role={'menu'}
                key={item.text}
                onMouseEnter={() => handleOpen(item)}
                onMouseLeave={(e) => handleClose(e, item)}
                style={{height: '100%'}}
                tabIndex={-1}
              >
                <Button
                  color={mainPage ? textColor : 'primary'}
                  size="medium"
                  className={
                    isActive(item.link)
                      ? styles['header__menu-group_active']
                      : ''
                  }
                  id='services_menu_button'
                  disableRipple={true}
                  href={item.link}
                  key={item.text}
                  ref={item.anchorRef}
                  aria-controls={item.isOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  endIcon={item.isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                >
                  {item.text}
                </Button>
                <Popper
                  placement={'bottom-start'}
                  open={item.isOpen}
                  anchorEl={item.anchorRef.current}
                  transition
                  disablePortal
                >
                  {({TransitionProps, placement}) => (
                    <Collapse
                      {...TransitionProps}
                      timeout={500}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                        background: '#fff',
                        zIndex: 200,
                      }}
                    >
                      <Paper
                        style={{
                          boxShadow: '0px 10px 30px rgba(52, 52, 52, 0.08)',
                          borderRadius: 0,
                        }}
                      >
                        <ClickAwayListener onClickAway={(e) => handleClose(e, item)}>
                          <div style={{
                            boxShadow: '0px 10px 30px rgba(52, 52, 52, 0.08)',
                            position: 'fixed',
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: 'space-between',
                            marginLeft: '-16px'
                          }}>
                            <MenuList
                              style={{
                                background: '#fff'
                              }}
                              autoFocusItem={item.isOpen}
                              id="menu-list-grow"
                            >
                              {item.children.map((childItem, index) => (
                                <MenuItem
                                  onClick={(e) => {
                                    handleSubItemClose(e, childItem);
                                    handleClose(e, item);
                                  }}
                                  onMouseEnter={() => handleSubItemOpen(childItem, index)}
                                  key={childItem.text}
                                  disableRipple={true}
                                >
                                  <GatsbyLink
                                    className={`${styles['header__menu-group__inner-link']} `}
                                    color={mainPage ? textColor : 'primary'}
                                    size="medium"
                                    to={childItem.link}
                                    activeStyle={{
                                      color: '#1a3c94',
                                      textDecoration: 'underline'
                                    }}
                                    style={{
                                      ...childItem.children && childItem.children.length && childItem.isOpen && {
                                        backgroundColor: '#F7F8FB'
                                      }
                                    }}
                                    ref={childItem.anchorRef}
                                  >
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                      {childItem.text}
                                      {childItem.children && childItem.children.length > 0
                                        ? (childItem.isOpen ? <NavigateBeforeIcon /> : <NavigateNextIcon />) : null
                                      }
                                    </div>
                                  </GatsbyLink>
                                </MenuItem>
                              ))}
                            </MenuList>
                            {currentSubItem && currentSubItem.activeItem && currentSubItem.activeItem.children && currentSubItem.activeItem.children.length > 0 && (
                              <MenuList
                                style={{
                                  background: '#F7F8FB',
                                  paddingTop: `${Math.min(56 * (item.children.length - currentSubItem.activeItem.children.length), Math.max(56 * (currentSubItem.index + 0.5 - Math.floor(currentSubItem.activeItem.children.length / 2)), 0))}px`
                                }}
                                autoFocusItem={currentSubItem.isOpen}
                                id="sub-menu-list-grow"
                                onMouseLeave={(e) => handleSubItemClose(e, currentSubItem.activeItem)}
                              >
                                {currentSubItem.activeItem.children.map(childItem => (
                                  <MenuItem
                                    className={styles['third-level-item']}
                                    onClick={(e) => {
                                      handleSubItemClose(e, currentSubItem.activeItem);
                                      handleClose(e, item);
                                    }}
                                    key={childItem.text}
                                    disableRipple={true}
                                  >
                                    {JSON.stringify(currentSubItem.activeItem.anchorHref)}
                                    <GatsbyLink
                                      className={`${styles['header__menu-group__inner-link']} `}
                                      color={mainPage ? textColor : 'primary'}
                                      size="medium"
                                      to={childItem.link}
                                      activeStyle={{
                                        color: '#1a3c94',
                                        textDecoration: 'underline'
                                      }}
                                    >
                                      {childItem.text}
                                    </GatsbyLink>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Collapse>
                  )}
                </Popper>
              </div>
            );
          }

          return (
            <Button
              className={
                isActive(item.link) ? styles['header__menu-group_active'] : ''
              }
              color={mainPage ? textColor : 'primary'}
              size="medium"
              disableRipple={true}
              href={item.link}
              key={item.text}
            >
              {item.text}
            </Button>
          );
        })}
        <Button
          classes={{ root: styles['header__contact-us'] }}
          color={mainPage ? buttonColor : 'secondary'}
          startIcon={<Mail />}
          // variant={mainPage ? 'contained' : ''}
          variant={'contained'}
          onClick={() => navigate('/contact-us')}
          id={'header_menu-button'}
        >
          Contact Us
        </Button>
      </div>

      <ClickAwayListener onClickAway={() => {
        setIsMobileMenuOpen(false);
        setCurrent({isMobileOpen: false});
        HEADER_MENU.forEach(i => {
          if ('isMobileOpen' in i) {
            i.isMobileOpen = false;
          }
          if (i.children) {
            i.children.forEach(c => {
              if ('isMobileOpen' in c) {
                c.isMobileOpen = false;
              }
            });
          }
        })
      }}>
        <div className={styles['header__mobile-menu__button']}>
          <div
            className={styles['header__mobile-menu__button']}
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
              setCurrent({isMobileOpen: false});
              HEADER_MENU.forEach(i => {
                if ('isMobileOpen' in i) {
                  i.isMobileOpen = false;
                }
                if (i.children) {
                  i.children.forEach(c => {
                    if ('isMobileOpen' in c) {
                      c.isMobileOpen = false;
                    }
                  });
                }
              })
            }}
            onKeyDown={e => {
              if (e.key !== 13) return;
              setIsMobileMenuOpen(!isMobileMenuOpen);
              setCurrent({isMobileOpen: false});
              HEADER_MENU.forEach(i => {
                if ('isMobileOpen' in i) {
                  i.isMobileOpen = false;
                }
                if (i.children) {
                  i.children.forEach(c => {
                    if ('isMobileOpen' in c) {
                      c.isMobileOpen = false;
                    }
                  });
                }
              })
            }}
            role={'button'}
            tabIndex={0}
          >
            {isMobileMenuOpen ? (
              <CloseIcon color={'secondary'}/>
            ) : (
              <MenuIcon color={mainPage ? buttonColor : 'secondary'}/>
            )}
          </div>

          <Collapse
            in={isMobileMenuOpen}
            classes={{container: styles['header__mobile-menu']}}
            style={{
              boxShadow: '0 36px 36px rgba(24, 31, 73, 0.08)',
              ...current.isMobileOpen && {
                maxHeight: 'calc(100vh - 70px)',
                position: 'fixed',
                top: '70px'
              }
            }}
          >
              {HEADER_MENU.map(item => {
                if (item.children && item.children.length) {
                  return (
                    <div key={item.text}>
                      <Button
                        color={mainPage ? textColor : 'primary'}
                        onClick={() => {
                          item.isMobileOpen = !item.isMobileOpen;
                          const otherItem = HEADER_MENU.find(i => 'isMobileOpen' in i && i.text !== item.text);
                          otherItem.isMobileOpen = false;
                          setCurrent({
                            isMobileOpen: !current.isMobileOpen,
                            activeItem: item
                          });
                        }}
                        size="medium"
                        id='services_menu_button'
                        disableRipple={true}
                        href={item.link}
                        key={item.text}
                        aria-controls={item.isOpen ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        endIcon={
                          item.isMobileOpen ? (
                            <ExpandLessIcon/>
                          ) : (
                            <ExpandMoreIcon/>
                          )
                        }
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {item.text}
                      </Button>
                      <Collapse
                        in={item.isMobileOpen}
                        classes={{
                          container: styles['header__mobile-submenu'],
                          hidden: styles['header__mobile-submenu__hidden'],
                        }}
                      >
                        {item.children.map(childItem => {
                          return childItem.children && childItem.children.length > 0
                            ? <div key={childItem.text} style={{
                              ...childItem.isMobileOpen && {
                                backgroundColor: '#F7F8FB'
                              }
                            }}>
                              <Button
                                color={mainPage ? textColor : 'primary'}
                                onClick={() => {
                                  childItem.isMobileOpen = !childItem.isMobileOpen;
                                  const otherItem = item.children.find(i => 'isMobileOpen' in i && i.text !== childItem.text);
                                  if (otherItem) {
                                    otherItem.isMobileOpen = false;
                                  }
                                  setCurrent({
                                    isMobileOpen: !current.isMobileOpen,
                                    activeItem: childItem
                                  });
                                }}
                                size="medium"
                                id='services_submenu_button'
                                disableRipple={true}
                                href="#"
                                key={childItem.text}
                                aria-controls={childItem.isOpen ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                endIcon={childItem.isMobileOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                {childItem.text}
                              </Button>
                              <Collapse
                                in={childItem.isMobileOpen}
                                classes={{
                                  container: styles['header__mobile-submenu'],
                                  hidden: styles['header__mobile-submenu__hidden'],
                                }}
                                style={{
                                  backgroundColor: '#F7F8FB',
                                  paddingLeft: '32px'
                                }}
                              >
                                <GatsbyLink
                                  key={childItem.mobileLinkText}
                                  // className={`${styles['header__menu-group__inner-link']} `}
                                  color={mainPage ? textColor : 'primary'}
                                  size="medium"
                                  to={childItem.link}
                                  activeStyle={{
                                    color: '#1a3c94',
                                    textDecoration: 'underline'
                                  }}
                                >
                                  {childItem.mobileLinkText}
                                </GatsbyLink>
                                {childItem.children.map(subItem => (
                                  <GatsbyLink
                                    key={subItem.text}
                                    // className={`${styles['header__menu-group__inner-link']} `}
                                    color={mainPage ? textColor : 'primary'}
                                    size="medium"
                                    to={subItem.link}
                                    activeStyle={{
                                      color: '#1a3c94',
                                      textDecoration: 'underline'
                                    }}
                                  >
                                    {subItem.text}
                                  </GatsbyLink>
                                ))}
                              </Collapse>
                            </div>
                            : <GatsbyLink
                              key={childItem.text}
                              // className={`${styles['header__menu-group__inner-link']} `}
                              color={mainPage ? textColor : 'primary'}
                              size="medium"
                              to={childItem.link}
                              activeStyle={{
                                color: '#1a3c94',
                                textDecoration: 'underline'
                              }}
                            >
                            {childItem.text}
                          </GatsbyLink>;
                        })}
                      </Collapse>
                    </div>
                  );
                }

                return (
                  <Button
                    className={
                      isActive(item.link)
                        ? styles['header__mobile-menu__active-link']
                        : ''
                    }
                    color={mainPage ? textColor : 'primary'}
                    size="medium"
                    disableRipple={true}
                    href={item.link}
                    key={item.text}
                    onClick={() => {
                      item.isMobileOpen = false;
                      const otherItem = HEADER_MENU.find(i => 'isMobileOpen' in i && i.text !== item.text);
                      otherItem.isMobileOpen = false;
                      setCurrent({isMobileOpen: false})
                    }}
                    style={{
                      boxShadow:
                        item.text === 'Company' && current.isMobileOpen
                          ? '0px -8px 12px rgba(24, 31, 73, 0.16)'
                          : 'none',
                    }}
                  >
                    {item.text}
                  </Button>
                );
              })}

              <Button
                classes={{root: styles['header__contact-us__mobile']}}
                color={'secondary'}
                startIcon={<Mail/>}
                variant={'contained'}
                onClick={() => navigate('/contact-us')}
                id={'header_menu-button'}
              >
                Contact Us
              </Button>
          </Collapse>
        </div>
      </ClickAwayListener>
    </div>
  );
};
export default Header;
