import React, {useState} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyLink from 'gatsby-link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import styles from './Cookies.module.scss';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Cookies = ({ onClose }) => {
  const initialCookie = {functional: true, preferences: true, statistics: true, thirdParty: true};
  const denyCookies = {functional: true, preferences: false, statistics: false, thirdParty: false };
  
  const [showSettings, setShowSettings] = useState(false);
  const [customizeCookies, setCustomizeCookies] = useState(initialCookie);

  const handleChange = (event) => {
    setCustomizeCookies({ ...customizeCookies, [event.target.name]: event.target.checked });
  };

  const { cookie } = useStaticQuery(graphql`
    query {
      cookie: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "newCookie" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);


  return (
    <div className={styles['cookie']}>
      <div className={styles['cookie__banner']}>
      <div className={styles['cookie__banner-body']}>
          <div className={styles['cookie__content']}>
            
            {/* <img src={cookie.nodes[0].publicURL} alt={'cookie'} /> */}
            <Typography variant={'body2'} className={styles['cookie__message']}>
              <h2>
                We use cookies
              </h2>
              We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your
              site usage data with our analytics partners. By continuing to the site, you consent to store on your device
              all the technologies described in our <GatsbyLink to="/cookies-policy">Cookie Policy</GatsbyLink>. Please read our <GatsbyLink to="/terms-of-use">Terms of Use</GatsbyLink> and <GatsbyLink to="/privacy-policy">Privacy Policy</GatsbyLink> for
              full details.
            </Typography>
            {showSettings &&  <FormGroup row>
              <FormControlLabel
                control={<Switch disabled checked name="functional" size='medium' />}
                labelPlacement='start'
                label={
                  <Typography variant={'body2'} className={styles['cookie__message']}>
                    <h5>Strictly necessary cookies</h5>
                    These cookies are necessary for the Sites to function and cannot be switched off in Our systems. They are usually only set in response to actions made by You that amount to a request for services, such as setting Your preferences, logging in, or filling out forms.
                 </Typography>}
              />
              <FormControlLabel
                control={<Switch checked={customizeCookies.preferences} onChange={handleChange} name="preferences" size='medium' />}
                labelPlacement='start'
                label={
                  <Typography variant={'body2'} className={styles['cookie__message']}>
                    <h5>Preferences cookies</h5>
                    Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.
                  </Typography>}
              />
              <FormControlLabel
                control={<Switch checked={customizeCookies.statistics} onChange={handleChange} name="statistics" size='medium'/>}
                labelPlacement='start'
                label={
                  <Typography variant={'body2'} className={styles['cookie__message']}>
                    <h5>Statistics cookies</h5>
                    Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.
                  </Typography>}
              />
              <FormControlLabel
                control={<Switch checked={customizeCookies.thirdParty} onChange={handleChange} name="thirdParty" size='medium'/>}
                labelPlacement='start'
                label={
                  <Typography variant={'body2'} className={styles['cookie__message']}>
                    <h5>Third-Party Cookies</h5>
                    Security cookies are used to identify malicious visitors on our sites in order to reduce the chance of blocking legitimate users. Google Analytics cookies allow us to count visits and traffic sources so that we could measure and improve the performance of our sites. They help us identify which pages are the most and least popular and see how visitors move around the sites.
                  </Typography>}
              />
            </FormGroup>}
          </div>
          <div className={styles['cookie__buttons-wrapper']}>
            <ul className={styles['cookie__btn-list']}>
              <li className={styles['cookie__btn-item']}>
                <Button color={'secondary'} variant={'outlined'} onClick={() => onClose(denyCookies)}>
                  Deny all
                </Button>
              </li>
              <li className={styles['cookie__btn-item']}>
                {showSettings ? <Button color={'secondary'} variant={'outlined'} onClick={() => onClose(customizeCookies)}>
                  Allow selection
                </Button>  : <Button endIcon={<ExpandMoreIcon/>} color={'secondary'} variant={'outlined'} onClick={() => setShowSettings(!showSettings)}>
                  Customize
                </Button>
                }
              </li>
              <li className={styles['cookie__btn-item']}>
                <Button color={'secondary'} variant={'contained'} onClick={() => onClose(initialCookie)}>
                  agree and close
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;