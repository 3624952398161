export const CDM_TITLE = 'Cloud Development & Migration';
export const CDM_SUBHEADING = 'CyberVision provides expert design and development services for new cloud applications, migration of existing software to the cloud, cloud performance and cost optimization, customization of popular cloud solutions, and full-stack DevOps assistance.';
export const CDM_CONSULTING_HEADING = 'Cloud Consulting Services';
export const CDM_CONSULTING_TEXT = 'With our deep knowledge of popular cloud technologies and platforms, we can help you implement the right technology for your business case, build a cloud migration roadmap, and assess multiple options for your digital transformation projects.';
export const CDM_CONSULTING_CARDS = [
  {
    icon: 'migration-roadmap',
    text: 'Build a clear strategy for cloud migration of your applications and data, assess cost savings and ROI, investigate available technologies and select the right option for your use case, and create a project plan for step-by-step cloud migration with minimal disruptions to your business operations.',
    title: 'Cloud migration roadmap'
  },
  {
    icon: 'cost-optimization',
    text: 'Identify necessary cloud computing resources based on your performance, availability, SLAs, and other requirements, and optimize your infrastructure overhead to meet those demands. Assess the future needs for cloud resources and plan for upgrades. Compare different hosting options, including hybrid and multi-cloud.',
    title: 'Cloud cost optimization'
  },
  {
    icon: 'performance-monitoring',
    text: 'Keep an eye on your cloud performance metrics, visualize real-time KPIs on a dashboard, set up automated alerts and notifications, and ensure faster times of problem resolution.',
    title: 'Performance monitoring'
  },
  {
    icon: 'cloudops',
    text: 'Establish efficient DevOps strategy for your cloud-native application stack, design and automate workflows, build a team of DevOps engineers and support specialists, and extend DevOps operations to cloud services and cloud-connected devices.',
    title: 'CloudOps'
  },
  {
    icon: 'multi-cloud-hosting',
    text: 'Increase agility of your application stack and address specific compliance, performance, cost, and security needs by hosting your solutions on multiple cloud platforms and/or multiple types of cloud (public, private, hybrid).',
    title: 'Multi-cloud hosting'
  },
  {
    icon: 'security-audit',
    text: 'Create a strong and resilient security architecture for your cloud solutions, implement modern identity and access management tools, data encryption and user management policies, implement secure IoT device management via the cloud, and check for existing vulnerabilities.',
    title: 'Security audit'
  }
];
export const CDM_MANAGEMENT_HEADING = 'Cloud Migration & Infrastructure Management';
export const CDM_MANAGEMENT_TEXT = 'Our software engineers and DevOps specialists can take your existing application stack to a new level of cost-efficiency, performance, and security, set up necessary infrastructure management tools, and build custom CloudOps solutions for your technology stack.';
export const CDM_MANAGEMENT_CARDS = [
  {
    icon: 'migration',
    text: 'Move your applications to the cloud and reduce business costs with flexible on-demand cloud computing resources, scale up easily when you need to, eliminate overprovisioning, and take advantage of modern cloud services offered by global technology leaders.',
    title: 'Cloud migration'
  },
  {
    icon: 'aws',
    text: 'Amazon Web Services is a dominant force in public cloud computing and a major player in AI, database, machine learning and serverless deployments. It has extensive, mature offerings as well as keeps launching new cloud services and computing innovations.',
    title: 'Migration to AWS'
  },
  {
    icon: 'gcp',
    text: 'Google Cloud Platform is built for cloud-native business applications and offers a complete container-based model at its core. It is also very cost-efficient and offers huge potential for AI- and ML-driven industry-specific solutions.',
    title: 'Migration to GCP'
  },
  {
    icon: 'azure',
    text: 'Microsoft Azure offers a broad feature set, integration with Microsoft software and tools, and support for open source, which makes it ideal for startups and developers.',
    title: 'Migration to MS Azure'
  },
  {
    icon: 'virtualization-containerization',
    text: 'Improve scalability, optimize server capacity, minimize cost, and streamline management of software deployments across multiple machines by taking advantage of virtual machines and containers for your specific use case and your IT strategy.',
    title: 'Virtualization/containerization'
  },
  {
    icon: 'provisioning-management',
    text: 'Ensure fast and reliable provisioning of cloud infrastructure for your applications and data, set up automation pipelines for new service provisioning, effectively apply Infrastructure as Code practice for the management of infrastructure, and take advantage of popular orchestration tools for your specific cloud deployment.',
    title: 'Infrastructure provisioning and management'
  }
];
export const CDM_DEVELOPMENT_HEADING = 'Cloud Application Development';
export const CDM_DEVELOPMENT_TEXT = 'We offer full-stack application development services based on modern cloud-native tools and platforms as well as popular technology domains such as Big Data, IoT, ML/AI, SaaS, Enterprise Software, Embedded, etc. ';
export const CDM_DEVELOPMENT_CARDS = [
  {
    icon: 'native',
    text: 'Build modern cloud native applications that simplify infrastructure management, offer great scalability and maintainability, and can be easily integrated with third-party applications and popular cloud services.',
    title: 'Cloud-native development'
  },
  {
    icon: 'modernization',
    text: 'Upgrade your existing applications and move them onto the modern microservice architecture, implement new features and integrations, refactor legacy code, improve performance and security, and build efficient CI/CD pipelines for further development.',
    title: 'Cloud application modernization'
  },
  {
    icon: 'service-integration',
    text: 'Take advantage of popular cloud services from different vendors and open-source solutions, and integrate them into your application stack; customize and configure third-party solutions to meet your unique objectives and user scenarios.',
    title: 'Cloud service integration'
  },
  {
    icon: 'gcp-stack',
    title: 'Implementation of GCP stack',
    text: 'Implement a broad variety of compute, storage, data engineering, analytics, AI/ML, database, and backup services offered by GCP for your specific use case. Utilize cutting-edge Google Cloud Data Fusion for streamlined data engineering and ETL pipelines development.'
  },
  {
    icon: 'aws-stack',
    text: 'Implement a broad variety of compute, storage, data engineering, analytics, AI/ML, database, and backup services offered by AWS for your specific use case. Take advantage of industry-specific solutions from AWS.',
    title: 'Implementation of AWS stack'
  },
  {
    icon: 'azure-stack',
    text: 'Implement a broad variety of compute, storage, data engineering, analytics, AI/ML, database, and backup services offered by MS Azure for your specific use case. Integrate with MS enterprise software.',
    title: 'Implementation of MS Azure stack'
  }
];
export const CDM_SUCCESS_STORIES_TEXT = 'Cloud application development and migration is one of our key areas of expertise, where we have dozens of success stories. Our experience isn’t limited by the big three cloud vendors - we’ve been working with a variety of popular cloud platforms and tools. We are also an official Google Cloud partner for solution development and integration on the GCP stack. We can definitely find the best fit for your business.';
export const CDM_USE_CASES_HEADING = 'Industries';
export const CDM_USE_CASES_CONTENT = [
  {
    image: 'it',
    text: 'Digital companies use cloud to build modern applications that can be easily accessed by their clients, quickly scaled to meet growing demand for resources, and constantly improved with innovative features and cloud services.',
    title: 'Information Technology'
  },
  {
    image: 'telecom',
    text: 'For Telecom operators, cloud is the way to expand their business while optimizing the cost of running their IT infrastructures. By utilizing modern cloud services, such as analytics and IoT, operators offer new value-added services to their clients as well as digitize their network management operations.',
    title: 'Telecom'
  },
  {
    image: 'manufacturing',
    text: 'Manufacturers achieve new levels of process automation and efficient supply chain management by creating end-to-end cloud-based solutions that allow for faster deployment, efficient performance and resource management, elastic scalability, and more streamlined third-party integrations.',
    title: 'Manufacturing'
  },
  {
    image: 'banking-insurance',
    text: 'Financial organizations use cloud platforms to improve resiliency and meet regulatory standards as well as take advantage of efficient data storage, advanced analytics and reporting tools, and modern data processing solutions. Cloud helps banks and insurance companies to improve their revenue generation strategies, optimize cost, and maximize quality of service for their clients.',
    title: 'Banking & Insurance'
  },
  {
    image: 'logistics',
    text: 'Cloud-based SaaS solutions enable logistics operators to connect diverse processes, applications, and data along their supply chains, ensure real-time visibility into their delivery processes, and connect multiple partners’ systems by synchronizing their data.',
    title: 'Logistics'
  },
  {
    image: 'utilities',
    text: 'Utilities companies use cloud to modernize their businesses with cloud-connected metering devices, more secure and scalable data storages, smart energy tools and dashboards for monitoring and management of energy, water, gas, etc. distribution and consumption, and fleet management solutions for optimized field service.',
    title: 'Utilities'
  },
  {
    image: 'healthcare',
    text: 'Cloud helps Healthcare providers to resolve their digital storage problems and take advantage of user-friendly and highly protected cloud storage services. They can also enjoy seamless integration of healthcare data between multiple applications, patient data analytics for better treatment and in-hospital experiences, faster deployment of smart medical devices, and modern patient-centric digital services. ',
    title: 'Healthcare'
  }
];
export const CDM_ADVANTAGES_CONTENT = [
  {
    number: '01',
    text: 'Broad expertise with popular cloud platforms and technology stacks',
    title: 'Expertise'
  },
  {
    number: '02',
    text: 'Official Google Cloud partner, certified engineers',
    title: 'GCP Partner'
  },
  {
    number: '03',
    text: 'Strong DevOps/CloudOps expertise and services',
    title: 'DevOps'
  },
  {
    number: '04',
    text: 'Full-stack cloud application development services',
    title: 'Full-Stack Development'
  }
];
export const CDM_INSIGHTS_HEADING = 'Insights';
