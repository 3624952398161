const SLIDERS = {
	kaa: {
		title: ['Champion your freedom', 'of IoT innovation'],
		text: ['With the next-gen Kaa IoT Platform originating from CyberVision'],
		link: 'https://kaaiot.com'
	},
	fourth: {
		title: ['Reimagine Cloud', 'and Big Data Innovations'],
		text: ['with the domain and technology experts']
	},
	first: {
		title: ['Migrate to the cloud with a preferred', 'vendor for Google Data Fusion'],
		text: ['Transfer your infrastructure, enterprise applications, or data', 'to the cloud in a fast, reliable, and cost-effective way']
	},
	second: {
		title: ['Enterprise on-prem support', 'for CDAP from expert contributors'],
		text: ['Effortless on-premise and cloud deployment', 'of the end-to-end data ingestion system']
	},
	third: {
		title: ['Full-stack Hadoop and', 'Data Analytics solutions'],
		text: ['We can help your business grow by taking advantage of', 'intelligent data management and modern analytics']
	},
	fifth: {
		title: ['Cloud cost optimization'],
		text: ['Save up to 40% on multi cloud deployments with Kubernetes']
	}
};

export default SLIDERS;
