import React from 'react';
import styles from './Footer.module.scss';
import Logo from '../../assets/images/logo.svg';
import Mail from '../../assets/images/mail.svg';
import Typography from '@material-ui/core/Typography';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import GatsbyLink from 'gatsby-link';
import {EXPERTISE_MENU, SERVICES_MENU} from '../../constants/menu';

const Footer = () => {
  const {s, google} = useStaticQuery(graphql`
    query {
      s: allFile(filter: { sourceInstanceName: { eq: "socialImages" } }) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
      google: allFile(
        filter: {
          sourceInstanceName: { eq: "socialImages" }
          name: { eq: "google" }
        }
      ) {
        nodes {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const iconsMap = s.nodes.reduce((acc, next) => {
    acc[next.name] = next.publicURL;
    return acc;
  }, {});

  const renderExpertiseMenu = () => EXPERTISE_MENU.map(({link, text}) => <li key={text}>
    <GatsbyLink to={link} className={styles['list-item-link']}>
      {text}
    </GatsbyLink>
  </li>);

  const renderServicesMenu = () => SERVICES_MENU.map(({link, text}) => <li key={text}>
    <GatsbyLink to={link} className={styles['list-item-link']}>
      {text}
    </GatsbyLink>
  </li>);

  return (
    <>
      <div className={styles['footer__google']}>
        <div className={styles['footer__google__info-wrapper']}>
          <Img
            className={styles['footer__google__img']}
            fluid={google.nodes[0].childImageSharp.fluid}
            key={google.nodes[0].childImageSharp.fluid}
          />
          <Typography classes={{root: styles['footer__google__text']}}>
            Google SI and PS partner
          </Typography>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles['footer__left-section']}>
          <Logo className={styles['footer__left-section_logo']}/>
          <div className={styles['footer__left-section_rights']}>
            <span>© 2020-2021 CyberVision, Inc, LLC.</span>
            <br/>
            <span>All Rights Reserved.</span>
          </div>
          <div className={styles['footer__left-section_terms']}>
            <GatsbyLink to={'/cookies-policy'}>
                Cookies Policy
            </GatsbyLink>
            <br/>
            <GatsbyLink to={'/terms-of-use'}>
              Terms of Use
            </GatsbyLink>
            <br/>
            <GatsbyLink to={'/privacy-policy'}>Privacy Policy</GatsbyLink>
          </div>
        </div>
        <div className={styles['footer__right-section']}>
          <div className={styles['footer__section-holder']}>
            <div className={styles['footer__section-services']}>
              <ul>
                <li>
                  <Typography
                    variant="body2"
                    component="span"
                    color="primary"
                    style={{fontWeight: 500}}
                  >
                    Services
                  </Typography>
                </li>
                {renderServicesMenu()}
              </ul>
              <ul>
                <li>
                  <Typography
                    variant="body2"
                    component="span"
                    color="primary"
                    style={{fontWeight: 500}}
                  >
                    Expertise
                  </Typography>
                </li>
                {renderExpertiseMenu()}
              </ul>
            </div>
            <div className={styles['footer__section-nav']}>
              <ul>
                <li>
                  <Typography
                    variant="body2"
                    component="span"
                    color="primary"
                    style={{fontWeight: 500}}
                  >
                    Company
                  </Typography>
                </li>
                <li>
                  <GatsbyLink to='/company' className={styles['list-item-link']}>
                    About us
                  </GatsbyLink>
                </li>
                <li>
                  <GatsbyLink to='/careers'className={styles['list-item-link']}>
                    Careers
                  </GatsbyLink>
                </li>
                <li>
                  <GatsbyLink
                    className={styles['footer__section-nav__link']}
                    to={'/clients-and-testimonials'}
                  >
                    Clients & Testimonials
                  </GatsbyLink>
                </li>
                <li>
                  <GatsbyLink
                    className={styles['footer__section-nav__link']}
                    to={'/blog'}
                  >
                    Blog
                  </GatsbyLink>
                </li>
                <li>
                  <GatsbyLink
                    className={styles['footer__section-nav__link']}
                    to={'/contact-us'}
                  >
                    Contact us
                  </GatsbyLink>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles['footer__section-addr']}>
            <ul>
              <li style={{marginBottom: 0}}>
                <Typography variant="body2" component="span" color="primary">
                  E-Mail:
                </Typography>
              </li>
              <li className={styles['footer__section-addr__email']}>
                <Mail/>
              </li>
            </ul>
            <ul>
              <li style={{marginBottom: 5}}>
                <Typography variant="body2" component="span" color="primary">
                  Mailing Address:
                </Typography>
              </li>
              <li className={styles['footer__section-addr__address']}>
                <Typography variant="subtitle2" component="span">
                  CyberVision, Inc.,
                </Typography>
              </li>
              <li className={styles['footer__section-addr__address']}>
                <Typography variant="subtitle2" component="span">
                  20801 Biscayne Blvd., Suite 403,
                </Typography>
              </li>
              <li className={styles['footer__section-addr__address']}>
                <Typography variant="subtitle2" component="span">
                  Aventura, FL 33180
                </Typography>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles['footer__social']}>
          <a
            className={styles['footer__social_block']}
            href={'https://www.linkedin.com/company/cybervision-inc-'}
            rel="noreferrer noopener"
            target="_blank"
          >
            <img
              className={styles['footer__social_block_linkedin']}
              src={iconsMap['linkedin']}
              alt="linkedin"
            />
          </a>
          <a
            className={styles['footer__social_block']}
            href={'https://www.facebook.com/cybervisionUSA'}
            rel="noreferrer noopener"
            target="_blank"
          >
            <img
              className={styles['footer__social_block_facebook']}
              src={iconsMap['facebook']}
              alt="facebook"
            />
          </a>
          <a
            className={styles['footer__social_block']}
            href={'https://twitter.com/CyberVisionUSA'}
            rel="noreferrer noopener"
            target="_blank"
          >
            <img
              className={styles['footer__social_block_twitter']}
              src={iconsMap['twitter']}
              alt="twitter"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
