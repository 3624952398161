export const GCP_TITLE = 'Google Cloud Platform';
export const GCP_MOBILE_LINK_TITLE = 'About GCP';
export const GCP_HEADING = 'Professional Support for Google Cloud Platform';
export const GCP_SUBHEADING = 'Our Google Cloud certified engineers can securely move your applications and data to Google Cloud Platform and enable you to take advantage of its industry-leading technology stack.';
export const GCP_SUPPORT_HEADING = 'Full technical support from an official Google Cloud Partner';
export const GCP_SUPPORT_FIRST_PARAGRAPH = 'As a Google Cloud Partner, CyberVision offers reliable and efficient technical support for diverse services and components of Google Cloud Platform. The vast majority of our clients who used our assistance to move their operations to GCP experienced a significant increase in efficiency, performance, and flexibility of their businesses. Additionally, they’ve come to discover more solutions within the GCP ecosystem that helped them further expand their capabilities and modernize their digital strategies.';
export const GCP_SUPPORT_LAST_PARAGRAPH = 'CyberVision offers in-depth knowledge and hands-on expertise in all popular services of Google Cloud Platform:';
export const GCP_SUPPORT_ICONS = [
  {
    icon: 'compute-app-engines',
    title: 'Compute and App Engines'
  },
  {
    icon: 'storage-databases',
    title: 'Storage and Databases'
  },
  {
    icon: 'data-analytics',
    title: 'Data Analytics'
  },
  {
    icon: 'cloud-migration',
    title: 'Cloud Migration'
  },
  {
    icon: 'anthos',
    title: 'Anthos'
  },
  {
    icon: 'ai-ml',
    title: 'AI/ML'
  },
  {
    icon: 'operations',
    title: 'Operations'
  },
  {
    icon: 'security-identity',
    title: 'Security and Identity'
  }
];
export const GCP_CONSULTING_HEADING = 'Consulting services';
export const GCP_CONSULTING_SUBHEADING = 'For companies who are only starting to implement Google Cloud solutions or developing a migration plan for their data and applications, CyberVision offers a tried-and-proven roadmap on how to make the most out of Google Cloud.';
export const GCP_CONSULTING_CARDS = [
  {
    icon: 'solution-architecture-design',
    text: ['Design a custom-tailored solution according to your business case and based on the right set of GCP services, third-party applications, and custom-built software.'],
    title: 'Solution architecture design',
  },
  {
    icon: 'solution-integration-design',
    text: ['Expand an existing solution with more services from the GCP stack or third-party frameworks and applications.'],
    title: 'Solution integrations design'
  },
  {
    icon: 'use-case-development',
    text: ['Develop and refine your business requirements for a solution in Google Cloud and translate them into in-detail technical requirements, delivery milestones, and project plan.'],
    title: 'Use case development'
  },
  {
    icon: 'migration',
    text: ['Create an implementation-ready roadmap for migration of your data and applications to GCP, which ensure minimal process disruption, cost, and risk for your line-of-business operations.'],
    title: 'Migration to GCP'
  },
  {
    icon: 'hybrid-multi-cloud',
    text: ['Develop an implementation strategy for applications running across hybrid cloud environments, run SWOT analysis, compare multiple deployment options in order to make the best decision ROI-wise.'],
    title: 'Hybrid and multi-cloud'
  },
  {
    icon: 'performance-optimization',
    text: ['Optimize rented cloud server capacities in order to ensure necessary performance while minimizing the cost; set up performance monitoring, implement policies for server scaling, automate capacity management, etc.'],
    title: 'Performance optimization'
  },
  {
    icon: 'security-audit-design',
    text: ['Create a strong and resilient security architecture for your GCP-based solutions, set up identity and access management components, data encryption and user management policies, etc.'],
    title: 'Security audit & design'
  },
  {
    icon: 'trainings',
    text: ['Train your in-house team on basic or advanced usage of your GCP-based solutions, run joint engineering sessions between your team and CyberVision to ensure knowledge and expertise transfer and faster learning curves of your engineers.'],
    title: 'GCP trainings'
  }
];
export const GCP_ENGINEERING_HEADING = 'Engineering services';
export const GCP_ENGINEERING_SUBHEADING = 'We build custom-tailored solutions based on Google Cloud Platform components and services, and fully integrate them into the client’s application landscape. Whether it’s an end-to-end cloud-native solution or a single custom-configured GCP service, we are here to help.';
export const GCP_ENGINEERING_CARDS = [
  {
    icon: 'setup-configuration',
    text: ['Quickly set up GCP services and components to streamline your solution implementation process, ensure accurate configuration across your entire solution stack, and verify performance in production.'],
    title: 'Setup and configuration of GCP services'
  },
  {
    icon: 'customization',
    text: ['Customize GCP services to meet your exact business goals and performance metrics, implement best practices and workflows alongside GCP service implementation, address your future roadmap while customizing GCP services.'],
    title: 'GCP service customization'
  },
  {
    icon: 'cloud-application-development',
    text: ['Create new cloud applications and services on the GCP stack, and build innovative products with the cloud-based software capabilities, upgrade legacy applications with a modern cloud-native architecture design.'],
    title: 'Cloud application development'
  },
  {
    icon: 'cloud-solution-integration',
    text: ['Integrate multiple systems and applications in a cloud-based environment powered by GCP, whether public cloud, bare metal, or hybrid; transfer existing data and applications into the cloud, or migrate from other vendors’ cloud technology.'],
    title: 'Cloud solution integration'
  },
  {
    icon: 'qa',
    text: ['Create and execute end-to-end testing plans for your cloud applications, root out potential vulnerabilities and bugs, ensure intuitive and efficient UI/UX design, develop automated tests, ensure sufficient and accurate documentation coverage, etc.'],
    title: 'Quality assurance'
  },
  {
    icon: 'devops-as-a-service',
    text: ['Build and automate CI/CD pipelines to ensure frequent releases of your cloud services based on GCP and minimize code integration challenges, implement infrastructure monitoring, SDLC automation, and other best practices that can improve your overall team’s productivity.'],
    title: 'DevOps as a service'
  }
];
export const GCP_MANAGED_HEADING = 'Managed services';
export const GCP_MANAGED_SUBHEADING = 'To support our clients’ day-to-day operations, ensure stable performance of their solutions in Google Cloud, and streamline incident resolution, we offer different types of managed services and technical support.';
export const GCP_MANAGED_CARDS = [
  {
    icon: 'cloud-infrastructure-setup-maintenance',
    text: ['Ensure timely provisioning of new cloud services and infrastructure to support your delivery process and meet other business objectives, streamline maintenance activities, and guarantee best SLAs for your clients.'],
    title: 'Cloud infrastructure setup & maintenance'
  },
  {
    icon: 'performance-monitoring-analysis',
    text: ['Continuously monitor performance of your cloud solutions, analyze any deviations, take proactive actions to mitigate any potential disruptions, and ensure that your users have everything up and running at all times.'],
    title: 'Performance monitoring & analysis'
  },
  {
    icon: 'scalability-planning',
    text: ['Manage capacity of your cloud servers to meet the demands of your business, satisfy peak demand, manage scalability of multi-cloud solutions, etc.'],
    title: 'Scalability planning'
  },
  {
    icon: 'cloud-storage-optimization',
    text: ['Ensure adequate storage capacity for your transactional data, streaming data, data warehouses, and data lakes; manage your GCP-based Big Data analytics infrastructure, track and optimize cloud storage cost.'],
    title: 'Cloud storage optimization'
  },
  {
    icon: 'access-security-management',
    text: ['Set up and maintain user access rights administration, implement security policies and monitor compliance, configure and support multi-tenant solution security, add new users, user roles, and access policies.'],
    title: 'Access & security management'
  },
  {
    icon: 'incident-management',
    text: ['Address any technical issues or user requests that may arise on an ad-hoc basis, ensure transparent issue escalation and their fast and efficient resolution.'],
    title: 'Incident management'
  }
];
export const GCP_READY_HEADING = 'Ready to get started?';
export const GCP_READY_SUBHEADING = [
  'Learn how Google Cloud Platform can help you meet and exceed your digital transformation objectives.'
];
export const GCP_WHY_GCP_HEADING = 'Why Google Cloud Platform';
export const GCP_WHY_GCP_PARAGRAPHS = [
  'Google Cloud Platform is currently one of the most advanced cloud frameworks that includes everything you may need to digitize your business - from efficient cloud storage and 24/7 access to your applications from anywhere in the world to best-in-class analytics capabilities and innovative self-managed services for IoT, ML/AI, and Data Science.',
  'With GCP, you can automate a vast majority of your operational routines, such as scaling your servers capacity, managing security, or monitoring application performance, and focus more on your product delivery processes and customer care.',
  'Finally, the GCP suite will get you involved in cutting-edge technology trends and revolutionary cloud services that may help you break fresh ground and create more attractive products for your customers.'
];
export const GCP_WHY_GCP_LIST_ITEMS = [
  'Anytime, anywhere availability of your apps and data', 'Effortless scalability and flexibility of server resources',
  'Cost-efficiency, pay as you go', 'Modern cloud-native architecture for your solutions',
  'Built-in security, resilience, back-up, etc.', 'Reliable vendor with strong future-proof strategy',
  'Perfect for data-driven businesses', 'Dynamically growing ecosystem'
];
export const GCP_WHY_GCP_BANNER_TEXT = 'Cloud Data Fusion is a powerful, cloud-native data integration service in Google Cloud Platform that enables users to easily build and manage ETL/ELT data pipelines via an intuitive graphical UI. It will dramatically streamline your data engineering and data analytics activities!';
export const GCP_WHY_GCP_BANNER_BUTTON = 'Learn more';
export const GCP_WHY_CV_HEADING = 'Why CyberVision';
export const GCP_WHY_CV_CONTENT = [
  {
    number: '01',
    title: 'Expertise',
    text: 'Broad expertise with popular cloud platforms and technology stacks'
  },
  {
    number: '02',
    title: 'Experience',
    text: 'Over 25 years in the industry, early adopters of cloud technologies'
  },
  {
    number: '03',
    title: 'GCP Partner',
    text: 'Official Google Cloud partner, GCP certified engineers'
  },
  {
    number: '04',
    title: 'DevOps',
    text: 'Strong DevOps/CloudOps expertise and services'
  },
  {
    number: '05',
    title: 'Full-Stack Development',
    text: 'Full-stack cloud application development services'
  }
];
export const GCP_INSIGHTS_HEADING = 'Insights';
