export const GCDF_MENU_TITLE = 'Cloud Data Fusion';

export const GCDF_HEADING = 'Enterprise Support for Google Cloud Data Fusion';

export const GCDF_SUBHEADING = 'As a team that largely contributed to the development of CDAP - a framework on which Cloud Data Fusion has been founded - we know both these tools inside out, including how to effectively apply them for enterprise use cases';

export const GCDF_WHAT_IS_GCDF_HEADING =
  'What is Google Cloud Data Fusion?';

export const WHAT_IS_GCDF_LEFT_PADDING =
  'Cloud Data Fusion (CDF) is a fully managed, cloud-native data integration service within Google Cloud Platform (GCP) that helps users efficiently build and manage ETL/ELT data pipelines. It features an intuitive graphical UI that replaces coding with visual layouts for your enterprise data pipelines. You can also take advantage of a broad collection of open-source transformations and connectors to speed up and simplify your data integration tasks.';

export const WHAT_IS_GCDF_RIGHT_PADDING =
  'Cloud Data Fusion is built on an open source technology called CDAP and, essentially, is a managed version of CDAP integrated in GCP. As a Google Cloud partner and the company offering enterprise support for CDAP, CyberVision is also an expert provider of CDF enterprise support services. We can help you kickstart your digital transformation efforts and get you right on track with modern tools, like GCP and CDF.';

export const GCDF_FEATURES_HEADING = 'CDF features';

export const GCDF_FEATURES = [
  {
    icon: 'code-free-ui',
    title: 'Code-free UI',
    text: 'Use straightforward point-and-click data integration to save time, improve learning curves, and minimize errors',
  },
  {
    icon: 'gpc-native',
    title: 'GCP-native',
    text: 'Unlock such advantages of Google Cloud as scalability, reliability, security, and privacy',
  },
  {
    icon: 'enterprise-grade-security',
    title: 'Enterprise-grade security',
    text: 'Use integration with Google Security services (IAM+IAP) to ensure enterprise-grade security',
  },
  {
    icon: 'seamless-operations',
    title: 'Seamless operations',
    text: 'Address all your operational monitoring and integration needs with REST APIs, state-based triggers, logs, time-based scheduling, pipeline, metrics and more',
  },
  {
    icon: 'integration-toolkit',
    title: 'Integration toolkit',
    text: 'Take advantage of built-in connectors for a variety of modern and legacy systems, code-free transformations, and error processing tools',
  },
  {
    icon: 'collaborative-data-engineering',
    title: 'Collaborative data engineering',
    text: 'Create and share an internal library of custom connections and transformations',
  },
];

export const GCDF_SERVICES_HEADING = 'Our services';

export const GCDF_SERVICES_TEXT = 'We provide expert software engineering, consulting, and system support services for Google Cloud Data Fusion and related Data Analytics components within GCP. Our Google certified engineers can help you address every part of Cloud Data Fusion implementation and ensure fast, reliable results.';

export const GCDF_SERVICES_LIST = [
  {
    title: 'Consulting services',
    items: [
      'Data infrastructure setup on GCP', 'Cloud Data Fusion configuration',
      'Configuration of GCP applications and tools', 'Infrastructure, data, and business application transfer',
      'GCP service architecture for data orchestration', 'Cloud Data Fusion use case development'
    ]
  },
  {
    title: 'Engineering & data engineering services',
    items: [
      'Data fetch from external data sources including Oracle, SAP, etc.', 'Cleansing and refining raw data',
      'Creating custom ETL/ELT data pipelines', 'ETL/ELT pipeline templates',
      'Advanced analytics design and implementation', 'Connecting an external analytics engine',
      'Custom software development', 'Data consolidation'
    ]
  },
  {
    title: 'Managed services',
    items: [
      'Cloud Data Fusion setup and maintenance', 'Performance monitoring', 'Troubleshooting',
      'Security & user management', 'Technical support', 'GCP infrastructure management'
    ]
  }
];

export const GCDF_WHY_CV_HEADING = 'Why CyberVision';

export const GCDF_WHY_CV_CONTENT = [
  {
    number: '01',
    title: 'From CDAP to CDF',
    text: 'We’ve made significant contributions to CDAP - a predecessor of CDF'
  },
  {
    number: '02',
    title: 'Expertise',
    text: 'Broad expertise with cloud platforms and Big Data technology'
  },
  {
    number: '03',
    title: 'Experience',
    text: 'Over 25 years in the industry, early adopters of cloud and Big Data'
  },
  {
    number: '04',
    title: 'GCP Partner',
    text: 'Official Google Cloud partner, GCP certified engineers'
  },
  {
    number: '05',
    title: 'Full-Stack Development',
    text: 'Full-stack cloud application development services'
  }
];

export const GCDF_INSIGHTS_HEADING = 'Insights';
