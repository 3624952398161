import {EXPERTISE_MENU, SERVICES_MENU} from './menu';

let HEADER_MENU: [
  { children: *, icon: string, anchorRef: any, isOpen: boolean, isMobileOpen: boolean, link: string, text: string },
  { children: *, icon: string, anchorRef: any, isOpen: boolean, isMobileOpen: boolean, link: string, text: string },
  { children: *, link: string, anchorRef: any, isOpen: boolean, isMobileOpen: boolean, text: string },
  { link: string, text: string },
  { link: string, text: string }
];
HEADER_MENU = [
  {
    text: 'Services',
    icon: 'keyboard_arrow_down',
    link: '#',
    isOpen: false,
    isMobileOpen: false,
    anchorRef: {current: null},
    children: SERVICES_MENU
  },
  {
    text: 'Expertise',
    icon: 'keyboard_arrow_down',
    link: '#',
    isOpen: false,
    isMobileOpen: false,
    anchorRef: {current: null},
    children: EXPERTISE_MENU
  },
  {
    text: 'Company',
    link: '#',
    isOpen: false,
    isMobileOpen: false,
    anchorRef: {current: null},
    children: [
      {
        text: 'About us',
        link: '/company'
      },
      {
        text: 'Careers',
        link: '/careers'
      }
    ]
  },
  {
    text: 'Clients & Testimonials',
    link: '/clients-and-testimonials',
  },
  {
    text: 'Blog',
    link: '/blog',
  },
];

export default HEADER_MENU;
