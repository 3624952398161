export const MTGC_MENU_TITLE = 'Cloud Migration';
export const MTGC_HEADING = 'Migration to Google Cloud';
export const MTGC_SUBHEADING = 'Boost your operational efficiencies and enjoy greater business automation with one of the most comprehensive and advanced cloud platforms to date';
export const MTGC_FIRST_BLOCK_HEADING = 'What is Google Cloud?';
export const MTGC_FIRST_BLOCK_TEXT = [
  'Google Cloud Platform (GCP), offered by Google, combines a cloud computing infrastructure, a development platform, and turn-key cloud services, enabling organizations to run a wide range of applications in the cloud. Whether it\'s simply moving on-premise data centers to the cloud for greater cost efficiency or taking advantage of modern cloud-native solutions, any company can use GCP to find their perfect cloud migration strategy.',
  'What makes GCP stand out among other large cloud providers? First and foremost, it’s Google’s strong vision for AI-enabled innovations powering cloud computing infrastructures and business applications. They offer a constantly growing ecosystem of solutions for Big Data analytics, AI, and ML that can be tightly integrated with the rest of GCP. It is also their cost-effective flexible pricing, great service level agreements, and a wide network of solution integration partners.',
];
export const MTGC_FIRST_BLOCK_BANNER_HEADING = 'Let us give you a tour!';
export const MTGC_FIRST_BLOCK_BANNER_TEXT = 'We’ll be glad to introduce you to major capabilities and benefits of Google Cloud on a quick live demo.';
export const MTGC_FIRST_BLOCK_BANNER_BUTTON = 'Contact us';
export const MTGC_SECOND_BLOCK_HEADING = 'Migration to Google Cloud with CyberVision';
export const MTGC_SECOND_BLOCK_SUBHEADING =
  'As a Google’s solution integration and platform support partner, CyberVision helps companies move their operations to Google Cloud';
export const MTGC_SECOND_BLOCK_TEXT =
  'With CyberVision, migration to GCP is a straightforward process that takes your company from A to Z in the shortest amount of time. We follow Google’s defined methodology for transfering infrastructure, applications, and data to avoid any disruption to your business operations. We also participate in annual Google Cloud events and continuously improve our skills in the latest GCP-based solutions. Our Google certified engineers can handle the entire migration project from scratch or augment your company’s team to speed up delivery of an ongoing project.';
export const MTGC_SECOND_BLOCK_SUBBLOCK = {
  heading: ['Our Services:'],
  content: [
    {
      icon: 'migration-to-gcp',
      title: 'Migration to GCP',
      body: ['Infrastructure', 'Applications', 'Workloads', 'Data', 'Setup of GCP services', 'Deployment optimization']
    },
    {
      icon: 'gcp-deployment',
      title: 'GCP managed services',
      body: [
        'Setup & configuration', 'Performance optimization', 'System integration', 'System maintenance',
        'System monitoring & troubleshooting', 'Security & user management'
      ]
    },
    {
      icon: 'advanced-solutions',
      title: 'Engineering services',
      body: [
        'Solution architecture development', 'Custom cloud-native applications', 'Legacy applications upgrade',
        'Big Data analytics', 'ML/AI', 'IoT'
      ]
    },
  ],
};
export const MTGC_THIRD_BLOCK_HEADING = 'Benefits of moving to Google Cloud with CyberVision';
export const MTGC_THIRD_BLOCK_CONTENT = [
  'Reduce business costs with flexible, on-demand cloud computing resources',
  'Scale up easily when you need to, eliminate overprovisioning',
  'Focus on business challenges, not infrastructure issues',
  'Take advantage of modern managed services from Google (BigQuery, CDF, etc.)',
  'Move from inefficient legacy architectures to reliable and secure cloud-native solutions',
  'Leverage the experience of our Google-certified engineers',
  'Leverage our full-stack enterprise software development expertise, DevOps, and technical support services'
];
export const MTGC_FOURTH_BLOK_HEADING = 'Take advantage of the GCP ecosystem';
export const MTGC_FOURTH_BLOCK_CONTENT = [
  [
    'GCP is a leading platform for artificial intelligence and machine learning applications. While many other cloud technology players are still focused primarily on traditional business applications, Google’s #1 priority is to create a new breed of enterprise applications powered by Machine Learning and AI. In that way, Google capitalizes on its broad expertise in cutting-edge technology to offer more value than its competitors.',
    'One example of Google’s growing ecosystem of innovative cloud apps is Cloud Data Fusion, launched in 2019. It is a fully managed, cloud-native data integration service that helps users build and manage ETL/ELT data pipelines via an intuitive graphical UI that almost totally eliminates the need for coding. CyberVision has already used this new tool for a large-scale Telecom Big Data project and it proved to be of great help for data engineers, business analysts, and project managers.',
  ],
  [
    'What makes Google Cloud Platform stand out from other cloud service providers is its clear focus on analytics and AI in the cloud. With GCP, you can actually connect all your data sources to Google cloud and take advantage of its end-to-end BI, analytics, and data visualization tools.',
    'Google Cloud Platform offers one of the best platforms for Internet of Things device management and analytics. You can use it to connect remote devices to the cloud, monitor real-time telemetry, set up alerts and notifications.',
    'Google Cloud Platform is also one of the most cost-efficient choices for cloud-based data warehousing and cloud computing. It has a very competitive pricing strategy and offers great SLAs on its infrastructure.',
  ],
];

export const MTGC_FIFTH_BLOCK_HEADING =
  'Our Google Cloud migration methodology';
export const MTGC_FIFTH_BLOCK_SUBHEADING = [
  'We apply a clear step-by-step methodology to handle every stage',
  'of migration to the cloud.',
];
export const MTGC_FIFTH_BLOCK_CONTENT = [
  {
    number: '01',
    title: 'Map out strategy',
    text: 'Assess resources and business objectives. Define roles, milestones, and metrics.',
  },
  {
    number: '02',
    title: 'Plan and prioritize',
    text: 'Design the process in detail. Prepare the migration plan. Prepare the back-up plan.',
  },
  {
    number: '03',
    title: 'Execute',
    text: 'Deploy. Migrate. Scale. Verify data, workloads, and applications.',
  },
  {
    number: '04',
    title: 'Test and validate',
    text: 'Test performance metrics. Monitor real-time changes.',
  },
  {
    number: '05',
    title: 'Optimize',
    text: 'Check the interactions between applications and the GCP environment. Optimize.',
  },
];
export const MTGC_TALK_BANNER_HEADING = 'Ready to talk to our experts?';
export const MTGC_TALK_BANNER_SUBHEADING = 'Send us a note and we’ll set up a meeting to answer all the technical questions you might have.';
export const MTGC_INSIGHTS_HEADING = 'Insights';
export const MTGC_CONTACT_US_TEXT = 'I would like to schedule a demo';
