import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import { useSiteMetadata } from '../../hooks';
import styles from './Layout.module.scss';
import Header from '../Header';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import Viewport from '../Viewport';
import Footer from '../Footer';
import theme from '../../theme';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import GatsbyLink from 'gatsby-link';
import { BREADCRUMBS_MAP } from '../../constants/breadcrumbs';
import Cookies from '../Cookies';
import TopPageBanner from '../TopPageBanner';
import { useLocation } from '@reach/router';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  socialImage?: string,
  mainPage?: string,
  location?: Object,
  pageTitle?: string,
  hideBreadcrumbs?: boolean,
};

const Layout = ({
  children,
  title,
  description,
  socialImage,
  mainPage,
  location,
  pageTitle = '',
  hideBreadcrumbs,
  darkBreadcrumbs,
}: Props) => {
  const { author, url } = useSiteMetadata();
  const [showCookiesBanner, setShowCookiesBanner] = useState(false);
  // Displays the site banner
  const [showTopBanner, setShowTopBanner] = useState(false);

  const metaImage = socialImage != null ? socialImage : author.photo;
  const metaImageUrl = url + withPrefix(metaImage);
  const getPaths = () => {
    const paths = location && location.pathname.split('/').filter(p => !!p);
    const lastPartOfPath = path => path.split('/')[path.split('/').length - 1];
    return paths
      .map((p, ind) => paths.slice(0, ind + 1).join('/'))
      .filter(
        p =>
          lastPartOfPath(p) !== 'category' &&
          lastPartOfPath(p) !== 'tag' &&
          lastPartOfPath(p) !== 'services' &&
          lastPartOfPath(p) !== 'expertise'
      );
  };
  const pathsResult = getPaths();
  let index;
  useEffect(() => {
    try {
      if(localStorage.getItem('isCookieBannerClosed') === null){
        setTimeout(() =>{
          localStorage.setItem('isCookieBannerClosed', false);
          setShowCookiesBanner(true);
        }, 0)
      } else {
        setShowCookiesBanner(!JSON.parse(localStorage.getItem('isCookieBannerClosed')));
      }

    } catch (e) {
    }
  }, []);
  
  const onCookiesBannerClose = (userAcceptedCookies) => {
    if (userAcceptedCookies) {
      document.getElementById('hs-eu-confirmation-button') !== null && document.getElementById('hs-eu-confirmation-button').click();
      localStorage.setItem('isCookiesAccepted', JSON.stringify(userAcceptedCookies));
      if (location && location.pathname === '/cookies-policy') {
        window.location.reload()
      }
    } else {
      document.getElementById('hs-eu-decline-button') !== null && document.getElementById('hs-eu-decline-button').click();
      localStorage.setItem('isCookiesAccepted', JSON.stringify(false));
    }

    setShowCookiesBanner(false);
    localStorage.setItem('isCookieBannerClosed', true);
  };

  const handleBannerClose = () => {
    setShowTopBanner(false);
  }

  return (
    <>
      <Viewport />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classnames(styles.layout, {[styles['layout--with-top-heading']]: showTopBanner})}>
          <Helmet>
            <html lang="en" />
            <title>{title}</title>
            {index && <meta name="robots" content="noindex"/>}
            <meta name="description" content={description} data-react-helmet="true" />
            <meta property="og:site_name" content={title} />
            <meta property="og:description" content={description} data-react-helmet="true"/>
            <meta name="image" property="og:image" content={metaImageUrl} />
            <meta property="og:image:secure_url" content={metaImageUrl} />
            <meta property="og:image:width" content={1280} />
            <meta property="og:image:height" content={640} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} data-react-helmet="true" />
            <meta name="twitter:image" content={metaImageUrl} />
            <meta name="google-site-verification" content="4mRv4anl8iIWjjwT5ONW8A9hdIv0EWawyo_T_pMwZYA" />
            <meta name="facebook-domain-verification" content="xd0slgds4oapvapkivvs8dpo71crro" />
          </Helmet>
          {showTopBanner && !location.pathname.includes("contact-us") && <TopPageBanner onClose={handleBannerClose} />}
          <Header mainPage={mainPage} location={location} />
          <div className={classnames(styles['layout__body'], !location.pathname.includes("contact-us") && {[styles['layout__body--with-top-heading']]: showTopBanner})}>
            {pathsResult && pathsResult.length > 0 && !hideBreadcrumbs && (
              <Breadcrumbs
                separator={'>'}
                className={darkBreadcrumbs ? styles['dark'] : ''}
              >
                <GatsbyLink to="/">HOME</GatsbyLink>
                {pathsResult.map((path, ind) => (
                  <GatsbyLink
                    key={path}
                    to={`/${path}`}
                    className={
                      ind === pathsResult.length - 1 ? styles['active'] : ''
                    }
                  >
                    {BREADCRUMBS_MAP[path] ? BREADCRUMBS_MAP[path] : pageTitle}
                  </GatsbyLink>
                ))}
              </Breadcrumbs>
            )}
            {children}
            {showCookiesBanner && <Cookies onClose={onCookiesBannerClose} />}
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;