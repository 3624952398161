export const BREADCRUMBS_MAP = {
  blog: 'BLOG',
  'clients-and-testimonials': 'CLIENTS AND TESTIMONIALS',
  company: 'ABOUT COMPANY',
  'services/cdap-support': 'CDAP SUPPORT',
  'expertise/google-cloud-platform/google-cloud-data-fusion': 'google cloud data fusion',
  'expertise/iot-development': 'Expertise: IOT Development',
  'services/technical-support': 'Services: Technical support',
  'services/software-development': 'services: software development',
  'services/it-managed-services': 'Services: IT Managed Services',
  'expertise/google-cloud-platform/migration-to-google-cloud': 'migration to google cloud',
  'expertise/big-data-analytics': 'Expertise: Big data Analytics',
  'expertise/devops': 'Expertise: DevOps',
  'expertise/google-cloud-platform': 'Expertise: Google Cloud Platform',
  'expertise/cloud-development-and-migration': 'Expertise: Cloud Development & Migration',
  'privacy-policy': 'privacy policy',
  'cookies-policy': 'cookies policy',
  'terms-of-use': 'terms of use',
  'careers': 'careers'
};
