import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#1A3C94',
    },
    error: {
      main: '#EB5757',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: '11px',
        fontWeight: 'normal',
        lineHeight: '1.1',
        '@media (max-width:600px)': {
          fontSize: '11px'
        },
        '@media (max-width:1279.95px)': {
          fontSize: '11px'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: '#1A3C94',
        fontWeight: '500',
        lineHeight: '28px',
        '&$focused': {
          color: '#1A3C94'
        }
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(0, 16px) scale(1)',
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderBottom: '1px solid #DFE4ED',
        },
      },
    },
    MuiButton: {
      root: {
        padding: '10px 24px',
        boxShadow: 'none',
        color: '#1A3C94',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: `0px 8px 48px rgba(40, 58, 153, 0.44)`,
        },
      },
      textPrimary: {
        color: '#1A3C94',
      },
      textSecondary: {
        color: '#FFFFFF',
      },
      containedPrimary: {
        color: '#1A3C94',
        fontWeight: 'bold',
      },
      containedSecondary: {
        color: '#FFFFFF',
        fontWeight: 'bold',
      },
    },

    MuiBreadcrumbs: {
      separator: {
        color: '#FFFFFF',
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: '#1D2A32',
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: '56px',
  lineHeight: '64px',
  fontWeight: '900',
  '@media (max-width:600px)': {
    fontSize: '28px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    lineHeight: '36px',

  },
};

theme.typography.h2 = {
  fontSize: '36px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '32px'
  },
};

theme.typography.h3 = {
  fontSize: '28px',
  lineHeight: '40px',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '24px'
  },
};

theme.typography.h4 = {
  fontSize: '22px',
  fontWeight: '500',
  lineHeight: '32px',
  marginTop: '2.4375rem',
  marginBottom: '0.8125rem',
  '@media (max-width:600px)': {
    fontSize: '11px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '24px'
  },
};

theme.typography.h5 = {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  '@media (max-width:600px)': {
    fontSize: '11px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
};

theme.typography.body1 = {
  fontSize: '18px',
  fontWeight: 'normal',
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '24px'
  },
};

theme.typography.body2 = {
  fontSize: '16px',
  lineHeight: '24px',
  '@media (max-width:600px)': {
    fontSize: '12px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
};

theme.typography.subtitle1 = {
  fontSize: '18px',
  fontWeight: 'normal',
  lineHeight: '28px',
  color: '#798294',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '24px'
  },
};

theme.typography.subtitle2 = {
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '28px',
  color: '#F7F8FB',
  opacity: 0.8,
};

theme.typography.caption = {
  fontSize: '44px',
  lineHeight: '64px',
  fontWeight: '900',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '36px'
  },
};
export default theme;
